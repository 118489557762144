import { Container, css } from '@mui/material'
import { ensureError } from '@orus.eu/error'
import { ButtonLink, spacing, Text, useCrash, useTranslate } from '@orus.eu/pharaoh'
import { isFailure } from '@orus.eu/result'
import type { ParameterlessTranslationKey } from '@orus.eu/translations'
import { useNavigate, useParams, useSearch } from '@tanstack/react-router'
import { memo, useEffect, useState, type FunctionComponent } from 'react'
import { trpc } from '../../client'
import { useSession } from '../../lib/session'
import { getSubscriptionInjectableParamsFromURLSearchParams } from '../../lib/subscription-injectable-params'
import { GlobalLoadingState } from '../molecules/global-loading-state'
import { InvalidLinkMessage } from '../organisms/invalid-link-message'

type Target = 'quote' | 'checkout'

type ReceiveQuoteVersion = 2 | 3

type ReceiveQuotePageProps = {
  version: ReceiveQuoteVersion
  target?: Target
}

export default memo(function ReceiveQuotePage({ version, target }: ReceiveQuotePageProps) {
  const params = useSearch({ strict: false })
  const { token } = useParams({ strict: false })

  const quoteToken = version === 2 ? params.token : token

  if (!quoteToken) {
    return <GlobalLoadingState />
  }

  return <QuoteReceivingHelper token={quoteToken} version={version} target={target ?? 'quote'} />
})

type AdoptionHelperProps = {
  token: string
  version: ReceiveQuoteVersion
  target: Target
}

const QuoteReceivingHelper: FunctionComponent<AdoptionHelperProps> = memo(function AdoptionHelper({
  token,
  version,
  target,
}) {
  const translate = useTranslate()
  const { permissions } = useSession()
  const [currentEdgeCase, setCurrentEdgeCase] = useState<HandledEdgeCase | 'invalid-link' | undefined>(undefined)
  const crash = useCrash()
  const navigate = useNavigate()
  const searchParams = useSearch({ strict: false })

  useEffect(() => {
    // TODO is it linked to a given permission?
    if (permissions.type === 'platform') {
      setCurrentEdgeCase('orus-session')
      return
    }

    let cancelled = false

    const injectedParamsResult = getSubscriptionInjectableParamsFromURLSearchParams(searchParams)

    if (isFailure(injectedParamsResult)) {
      setCurrentEdgeCase('invalid-link')
      return
    }

    const injectedParams = injectedParamsResult.output

    const receiveQuoteApiCall =
      version === 2
        ? trpc.subscriptions.receiveQuoteWithV2Token.query(token)
        : trpc.subscriptions.receiveQuoteV4.query({ token, injectedParams })

    receiveQuoteApiCall
      .then((result) => {
        if (cancelled) return

        if (result === 'invalid-token' || result === 'invalid-injected-params') {
          setCurrentEdgeCase('invalid-link')
          return
        }

        if (isFailure(result)) {
          setCurrentEdgeCase(result.problem)
          return
        }

        const subscriptionId = result.output
        void navigate({
          to: '/subscribe/$subscriptionId/$stepId',
          params: { subscriptionId, stepId: target },
          search: { arrives_receive_quote: true },
        })
      })
      .catch((err: unknown) => {
        if (cancelled) return

        crash(ensureError(err))
      })

    return () => {
      cancelled = true
    }
  }, [crash, navigate, token, version, target, permissions.type, searchParams])

  if (currentEdgeCase) {
    return (
      <Container maxWidth="sm">
        {currentEdgeCase === 'invalid-link' ? (
          <InvalidLinkMessage />
        ) : (
          <>
            <Text
              variant="h3"
              css={css`
                margin-top: ${spacing[90]};
              `}
            >
              {translate(issuePageTitle[currentEdgeCase])}
            </Text>
            <Text
              variant="body1"
              css={css`
                margin-top: ${spacing[70]};
              `}
            >
              {translate(issuePageExplanation[currentEdgeCase])}
            </Text>
            <div
              css={css`
                margin-top: ${spacing[70]};
              `}
            >
              {currentEdgeCase === 'contract-already-signed' ? (
                <ButtonLink
                  variant="primary"
                  to="/login"
                  icon="arrow-right-regular"
                  avatarPosition="right"
                  size="medium"
                >
                  Me connecter
                </ButtonLink>
              ) : (
                <></>
              )}
            </div>
          </>
        )}
      </Container>
    )
  }

  return <GlobalLoadingState />
})

type HandledEdgeCase = 'contract-already-signed' | 'orus-session'

const issuePageTitle: Record<HandledEdgeCase, ParameterlessTranslationKey> = {
  'contract-already-signed': 'receive_quote_already_signed_title',
  'orus-session': 'receive_quote_bad_sequence_title',
}

const issuePageExplanation: Record<HandledEdgeCase, ParameterlessTranslationKey> = {
  'contract-already-signed': 'receive_quote_already_signed_explanation',
  'orus-session': 'receive_quote_bad_sequence_explanation',
}
